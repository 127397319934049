
<template>
  <Modal
    :activeModal="isActiveModal"
    :widthModal="450"
    :isPersistent="true"
  >

    <v-card v-if="loading">
      <v-col class="d-flex justify-center align-center" style="min-height: 150px; width: 100%;">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-card>

    <v-card v-else>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        Pegar a senha com o seu Supervisor
        <v-spacer></v-spacer>
      </v-toolbar>
      
      <v-card-text
        class="white--text align-end pa-2"
      >
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12">
          <v-text-field 
            type="text" 
            v-model="password"
            v-mask="'######'"
            label="Senha"
            outlined
            dense
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            :error-messages="passwordErrors"
          />
        </v-col>
      </v-card-text>

      <v-card-actions>
        <v-col :cols="12" class="align-end d-flex" style="width: 100%;">
          <v-btn 
          :color="variables.colorPrimary"
          @click="confirmPassword"
          :loading="loadingBtn"
          class="br-btn br-btn-cancelar">
            Confirmar senha
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </Modal>
</template>

<script>
// eslint-disable-next-line
import { mapActions, mapGetters } from 'vuex'
// import Events from '@/core/service/events'
// eslint-disable-next-line
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import { clone, filter, size, cloneDeep } from 'lodash'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import { mask } from 'vue-the-mask'
export default {
  name: 'ModalConfirmationPaymentPix',
  directives: { mask },
  props: {
    isActiveModal: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: (() => {})
    }
  },
  components:{
    Modal: () => import('@/views/components/modal')
  },
  data: () => ({
    modelSlide: null,
    loading: false,
    loadingBtn: false,
    menuDateInicial: false,
    dtInicial: '',
    activeModal: false,
    input: '',
    file: null,
    promotor: {},
    qtdItemForm: 1,
    dataAnexo: {},
    form: {
      item1: {
        anexo: '',
        description: '',
        name: ''
      }
    },
    password: ''
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    variables: () => variables,

    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Campo Obrigatório')
      !this.$v.password.minLength && errors.push('Código inválido')
      !this.$v.password.maxLength && errors.push('Código inválido')
      return errors
    },
  },
  mounted () {
    this.loading = true
    // this.solicitarSenha()
    //   .then(() => successSnackbar('Código enviado'))
    //   .finally(() => this.loading = false)
  },
  watch: {
  },
  methods: {
    ...mapActions('estabelecimento', ['validarSenha', 'solicitarSenha']),

    closeModal () {
      this.$emit('closeModal', false)
    },

    confirmPassword () {
      this.loadingBtn = true
      this.validarSenha({ codigo: this.password })
        .then(() => {
          successSnackbar('Código validado com sucesso!')
          this.$emit('closeModal', true)
        })
        .catch((err) => errorSnackbar(err.error))
        .finally(() => this.loadingBtn = false)
    }
  },

  validations () {
    return  {
     password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
     }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/components/modal-cadastro';
.modal-cadastro-form-col-input .auto {
  background: red;
}
.theme--light.v-input input, .theme--light.v-input textarea{
  padding: 2;
}
.theme--light.v-input{
  padding: 0;
  margin: 0;
}
#bug{
  margin-top: 7px !important
}
</style>
